// Here you can add other styles

.d-flex{
    display: inline-flex !important;
}
.invoice-switch .switch{
    margin-left: 10px;
}
.ml-10 {
    margin-left: 10px;
}
.danger-text{
    color: red;
}
@media only screen and (max-width: 767px) {
    .app-header{
        overflow-x: auto;
    }
    th,td{
        width: 200px !important;
    }
    .react-bootstrap-table{
        overflow-x: auto;
    }
  }


body {
    --ck-z-default: 100;
    --ck-z-modal: calc( var(--ck-z-default) + 999 );
}